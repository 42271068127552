<template>
  <div class="aboutcontent">
    <div class="banner">
      <img src="./images/banner.png" alt="" />
    </div>
    <div class="advantage w2">
      <div class="item">
        <img src="./images/speciality.png" alt="" />
        <h3>专业</h3>
        <p>专业师资团队倾力打造</p>
      </div>
      <div class="verline"></div>
      <div class="item">
        <img src="./images/richness.png" alt="" />
        <h3>丰富</h3>
        <p>海量词典语料库和课程</p>
      </div>
      <div class="verline"></div>
      <div class="item">
        <img src="./images/efficient.png" alt="" />
        <h3>高效</h3>
        <p>方便快捷查词超简单</p>
      </div>
      <div class="verline"></div>
      <div class="item">
        <img src="./images/novel.png" alt="" />
        <h3>新颖</h3>
        <p>趣味学习爱上西语</p>
      </div>
    </div>

    <div class="profile w2">
      <h3>北京结藤社文化发展有限公司</h3>
      <h4>Company Profile</h4>
      <div class="content">
        <img src="./images/left.png" alt="" />
        <p>
          结藤社成立超过10年了，被人赞，也被人骂过；但是，人在江湖飘，谁能不挨刀？我们无法满足所有人的需求，但是我们以本着免费或者低成本帮助各种需求的同学，尽量开发性价比高的产品：在线阅读、刷题平台、专四专八备考秘籍课程、DELE备考课程、零基础课程、词典、句对齐语料库等。
        </p>
        <img class="right" src="./images/right.png" alt="" />
      </div>
    </div>
    <div class="tool">
      <div class="w2 toollist">
        <div class="toolitem">
          <h3 class="titlefont121">
            2020年我们开始开发旨在<br />帮助西语学习者进行<br />智能学习的工具
          </h3>
          <h4 class="titlefont13">
            虽然其开发成本远远超出我们的预期，但是我们依然认真的去对待所有词典、语料库的电子化编辑，并投入大量技术开发资金。此工作量巨大，说真的，我们好几位老师的眼睛都快累瞎了。在这里仅举出两个具体的工作例子。
          </h4>
        </div>
        <div class="toolitem">
          <img src="./images/img1.png" alt="" />
          <h5 class="color1 font18500">汉西词典</h5>
          <p class="grey1 font16400">
            汉西词典是几位西班牙汉学家编撰的，也就是这本词典是西班牙母语学者根据他们对中文的理解，做出的西班牙语解释；如果你们去查询单字汉语对应的西班牙语释义，你就会发现这是一个宝藏，里面所有的繁体字OCR都是我们人工逐字逐句校对出来的。
          </p>
        </div>
        <div class="toolitem">
          <img src="./images/img2.png" alt="" />
          <h5 class="color1 font18500">句对齐语料库</h5>
          <p class="grey1 font16400">
            我们的句对齐语料库（中西西中人工翻译语料）已经做到词量4000多万，这个在全世界也是佼佼者，而且还在不断的增加新的内容。
          </p>
        </div>
      </div>
    </div>
    <div class="introduce w2">
      <div class="introduceitem">
        <h3 class="font36500 black1">丰富的多媒体课程</h3>
        <p class="font22400 grey1">
          我们给西语学习和送出的福利也可以在“多媒体课程”中得到体现，除了大量免费的零基础，外刊、西班牙语的影视剧作品，还提供专门针对学习者考试辅导的DELE、专四、专八辅导课程。
        </p>
      </div>
      <div class="introduceitem textCenter">
        <img class="img3" src="./images/im31.png" alt="" />
        <img class="jian1" src="./images/jian.png" alt="" />
      </div>
      <div class="introduceitem">
        <img class="img4" src="./images/img4.png" alt="" />
        <img class="jian2" src="./images/jian.png" alt="" />
      </div>
      <div class="introduceitem">
        <h3 class="font36500 black1">AI语伴功能</h3>
        <p class="font22400 grey1">
          更值得一提的是AI语伴功能，实际上就是前段时间特别火的人工智能（微软投资的那家，由于我们作为正规接口接入的第三方不能展示其品牌的，但是相信聪明的你们一定猜到了是谁），我们都是成本价奉献给我们APP会员！
        </p>
      </div>
    </div>
    <div class="down">
      <p class="font22400 black1">
        目前我们正在开发一款强大的社交类学习工具，这里卖个关子，希望我们在APP里面进行互动游戏！<br />我们不能保证我们的产品100%完美，但是我们承诺会定期带给会员新功能的惊喜！
      </p>
      <h3 class="black2 font48500">学西语 不孤单</h3>
      <div class="bluebutton">
        <router-link class="white awhite" to="/download">下载APP</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {


}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
.awhite:hover {
  color: #fff;
  text-decoration: none;
}
.aboutcontent {
  min-width: 1200px;
  background-color: #fff;
  padding-bottom: 80px;
  // position: relative;
  .banner {
    width: 100%;
    // height: 530px;
    // position: relative;
  }
  .advantage {
    height: 220px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 7px 6px rgba(207, 207, 207, 0.25);
    display: flex;
    justify-content: space-around;
    align-items: center;
    // position: absolute;
    // top: 450px;
    // left: 50%;
    // transform: translateX(-50%);
    position: relative;
    margin-top: -80px;

    .item {
      // padding: 37px 70px;
      width: 24%;
      text-align: center;

      img {
        width: auto;
        height: 78px;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        line-height: 2;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(119, 119, 119, 1);
        line-height: 2;
      }
    }
    .verline {
      width: 3px;
      height: 104px;
      border-radius: 210px;
      background: rgba(238, 238, 238, 1);
    }
  }
  .profile {
    height: 302px;
    margin-top: 80px;
    padding: 60px 127px 0;
    // background: url("./images/back.png") no-repeat;
    opacity: 0.9;
    background-image: linear-gradient(
        90deg,
        rgba(238, 247, 255, 1) 0%,
        rgba(217, 236, 252, 0.97) 99.67%
      ),
      url("./images/profile.png");
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      text-align: center;
    }
    h4 {
      font-size: 20px;
      font-weight: 400;
      color: rgba(170, 170, 170, 1);
      text-align: center;
      line-height: 2;
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      img {
        width: 23px;
        height: 32px;
      }
      .right {
        margin-bottom: 0px;
      }
    }
    p {
      text-align: justify;
      font-size: 20px;
      font-weight: 400;
      color: rgba(79, 79, 79, 1);
      line-height: 1.5;
    }
  }
  .tool {
    width: 100%;
    height: 456px;
    // opacity: 0.6;
    margin-top: 80px;
    background: rgba(243, 245, 248, 1);
    .toollist {
      padding-top: 70px;
      display: flex;
      justify-content: space-between;
      .toolitem {
        width: 324px;
        text-align: justify;
        line-height: 1.5;
        img {
          width: 324px;
          height: 140px;
        }
      }
      .toolitem:first-child {
        width: 367px;
      }
      h4 {
        font-weight: 400;
        margin-top: 30px;
      }
    }
  }
  .introduce {
    display: flex;
    flex-wrap: wrap;
    .introduceitem {
      width: 50%;
      margin-top: 60px;
      position: relative;
      .img3 {
        // width: 530.25px;
        // height: 309px;
        width: 478px;
        height: 291.51px;
      }
      .jian1 {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 150px;
        right: 44px;
      }
      .jian2 {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 178px;
        right: 240px;
      }
      .img4 {
        width: 520px;
        height: 285px;
      }
    }
  }
  .textCenter {
    text-align: center;
  }
  .down {
    width: 100%;
    height: 344px;
    padding: 48px 0;
    opacity: 1;
    background: rgba(238, 247, 255, 1);
    text-align: center;
    h3 {
      line-height: 2.8;
    }
  }
}
</style>